import { lazy } from "react";
import AppPaths from "./AppPaths";

const Home = lazy(() => import("../themes/default/routes/Home/Home"));
const Login = lazy(() => import("../themes/default/routes/Login/Login"));
const TwoFactorAuth = lazy(() =>
  import("../themes/default/routes/TwoFactorAuth/TwoFactorAuth")
);

const LoginTwoFactorAuth = lazy(() =>
  import("../themes/default/routes/LoginTwoFactorAuth/LoginTwoFactorAuth")
);

const Signup = lazy(() => import("../themes/default/routes/Signup/Signup"));
const BrowseCompanies = lazy(() =>
  import("../themes/default/routes/BrowseCompanies/BrowseCompanies")
);
const BrowseSuccessCampaigns = lazy(() =>
  import(
    "../themes/default/routes/BrowseSuccessCampaigns/BrowseSuccessCampaigns"
  )
);
const RaiseCapital = lazy(() =>
  import("../themes/default/routes/RaiseCapital/RaiseCapital")
);
const Faq = lazy(() => import("../themes/default/routes/Faq/Faq"));
const Dashboard = lazy(() =>
  import("../themes/default/routes/Dashboard/Dashboard")
);
const ForgotPassword = lazy(() =>
  import("../themes/default/routes/ForgotPassword/ForgotPassword")
);
const CampaignDetails = lazy(() =>
  import("../themes/default/routes/CampaignDetails/CampaignDetails")
);

const Profile = lazy(() => import("../themes/default/routes/Profile/Profile"));
const ProfileSettings = lazy(() =>
  import("../themes/default/routes/Settings/ProfileSettings/ProfileSettings")
);
const TwoFactorAuthentication = lazy(() =>
  import(
    "../themes/default/routes/Settings/TwoFactorAuthentication/TwoFactorAuthentication"
  )
);
const EmailPreferences = lazy(() =>
  import("../themes/default/routes/Settings/EmailPreferences/EmailPreferences")
);
const ChangePassword = lazy(() =>
  import("../themes/default/routes/Settings/ChangePassword/ChangePassword")
);
const OutsideLinks = lazy(() =>
  import("../themes/default/routes/Settings/OutsideLinks/OutsideLinks")
);
const InvestmentAccount = lazy(() =>
  import(
    "../themes/default/routes/Settings/InvestmentAccount/InvestmentAccount"
  )
);
const PaymentOptions = lazy(() =>
  import("../themes/default/routes/Settings/PaymentOptions/PaymentOptions")
);
const Inbox = lazy(() => import("../themes/default/routes/Inbox/Inbox"));

const Invite = lazy(() => import("../themes/default/routes/Invite/Invite"));
const MyInvestments = lazy(() =>
  import("../themes/default/routes/MyInvestments/MyInvestments")
);
const Investment = lazy(() =>
  import("../themes/default/routes/Contribution/Investment/Investment")
);
const Payment = lazy(() =>
  import("../themes/default/routes/Contribution/Payment/Payment")
);
const InvestmentReceipt = lazy(() =>
  import(
    "../themes/default/routes/Contribution/InvestmentReceipt/InvestmentReceipt"
  )
);
const CompanyInfo = lazy(() =>
  import("../themes/default/routes/CreateCampaign/CompanyInfo/CompanyInfo")
);
const FundraisingDetail = lazy(() =>
  import(
    "../themes/default/routes/CreateCampaign/FundraisingDetail/FundraisingDetail"
  )
);
const Story = lazy(() =>
  import("../themes/default/routes/CreateCampaign/Story/Story")
);
const Faqs = lazy(() =>
  import("../themes/default/routes/CreateCampaign/Faqs/Faqs")
);
const Extras = lazy(() =>
  import("../themes/default/routes/CreateCampaign/Extras/Extras")
);
const Funding = lazy(() =>
  import("../themes/default/routes/CreateCampaign/Funding/Funding")
);
const ThankYou = lazy(() =>
  import("../themes/default/routes/CreateCampaign/ThankYou/ThankYou")
);
const CampaignDashboard = lazy(() =>
  import("../themes/default/routes/CampaignDashboard/CampaignDashboard")
);
const RepaymentForm = lazy(() =>
  import(
    "../themes/default/routes/CampaignDashboard/RepaymentForm/RepaymentForm"
  )
);
const Repayment = lazy(() =>
  import("../themes/default/routes/CampaignDashboard/Repayment/Repayment")
);
const RepaymentReceipt = lazy(() =>
  import(
    "../themes/default/routes/CampaignDashboard/RepaymentReceipt/RepaymentReceipt"
  )
);
const SavedCampaign = lazy(() =>
  import("../themes/default/routes/SavedCampaign/SavedCampaign")
);
const HelpCenter = lazy(() =>
  import("../themes/default/routes/HelpCenter/HelpCenter")
);
const QuestionList = lazy(() =>
  import("../themes/default/routes/HelpCenter/QuestionList")
);
const SingleQuestion = lazy(() =>
  import("../themes/default/routes/HelpCenter/SingleQuestion")
);
const EmailVerification = lazy(() =>
  import("../themes/default/components/EmailVerification/EmailVerification")
);
const ContentPage = lazy(() =>
  import("../themes/default/routes/ContentPage/ContentPage")
);
const Terms = lazy(() => import("../themes/default/routes/Terms/Terms"));
const AccountActivated = lazy(() =>
  import("../themes/default/routes/AccountActivated/AccountActivated")
);
const ResetPassword = lazy(() =>
  import("../themes/default/routes/ResetPassword/ResetPassword")
);
const ProjectGuidelines = lazy(() =>
  import(
    "../themes/default/routes/CreateCampaign/ProjectGuidelines/ProjectGuidelines"
  )
);
const InvestmentPaymentReceipt = lazy(() =>
  import(
    "../themes/default/routes/Contribution/InvestmentPaymentReceipt/InvestmentPaymentReceipt"
  )
);

const ContactUs = lazy(() =>
  import("../themes/default/routes/ContactUs/ContactUs")
);

const DeleteAccountRequest = lazy(() =>
  import("../themes/default/routes/DeleteAccountRequest/DeleteAccountRequest")
);

const UserKYC = lazy(() =>
  import("../themes/default/routes/Settings/UserKYC/UserKYC")
);

const StripeConnect = lazy(() =>
  import("../themes/default/routes/StripeConnect/StripeConnect")
);

const StripeACHAccounts = lazy(() =>
  import(
    "../themes/default/routes/Settings/StripeACHAccounts/StripeACHAccounts"
  )
);
const PlaidDwollaConnect = lazy(() =>
  import(
    "../themes/default/routes/Settings/PlaidAccountConnect/PlaidAccountConnect"
  )
);

const ResendEmail = lazy(() =>
  import("../themes/default/routes/ResendEmail/ResendEmail")
);

const TopupPayment = lazy(() =>
  import("../themes/default/routes/TopupPayment/TopupPayment")
);

const TopupReceiptFail = lazy(() =>
  import("../themes/default/routes/TopupPayment/TopupReceipt/TopupReceiptFail")
);

const TopupReceipt = lazy(() =>
  import("../themes/default/routes/TopupPayment/TopupReceipt/TopupReceipt")
);

const Wallet = lazy(() => import("../themes/default/routes/Wallet/Wallet"));

const WalletPayment = lazy(() =>
  import("../themes/default/routes/Contribution/WalletPayment/WalletPayment")
);

const SaveInvestment = lazy(() =>
  import("../themes/default/routes/SavedInvestment/SavedInvestment")
);

const MangoPayWallet = lazy(() =>
  import("../themes/default/routes/MangoPayWallet/MangoPayWallet")
);

const MangoPayTopupReceipt = lazy(() =>
  import(
    "../themes/default/routes/MangoPayTopupPayment/MangoPayTopupReceipt/MangoPayTopupReceipt"
  )
);

const ShareCertificates = lazy(() =>
  import("../themes/default/routes/ShareCertificates/ShareCertificates")
);

const UserNotifications = lazy(() =>
  import("../themes/default/routes/UserNotifications/UserNotifications")
);

const SecondaryMarketBrowse = lazy(() =>
  import("../themes/default/routes/SecondaryMarket/Browse/Browse")
);

const SellerStepsShareDetails = lazy(() =>
  import(
    "../themes/default/routes/SecondaryMarket/Seller/SellerSteps/SellerShareDetails/SellerShareDetails"
  )
);

const SellerStepsAgreement = lazy(() =>
  import(
    "../themes/default/routes/SecondaryMarket/Seller/SellerSteps/Agreement/Agreement"
  )
);

const SellerStepsSummary = lazy(() =>
  import(
    "../themes/default/routes/SecondaryMarket/Seller/SellerSteps/Summary/Summary"
  )
);

const SellerDashboard = lazy(() =>
  import(
    "../themes/default/routes/SecondaryMarket/Seller/SellerDashboard/SellerDashboard"
  )
);

const BuyerStepsShareDetails = lazy(() =>
  import(
    "../themes/default/routes/SecondaryMarket/Buyer/BuyerSteps/BuyerShareDetails/BuyerShareDetails"
  )
);

const BuyerStepsPayment = lazy(() =>
  import(
    "../themes/default/routes/SecondaryMarket/Buyer/BuyerSteps/BuyerPayment/BuyerPayment"
  )
);

const BuyerStepsSummary = lazy(() =>
  import(
    "../themes/default/routes/SecondaryMarket/Buyer/BuyerSteps/BuyerSummary/BuyerSummary"
  )
);

const BuyerDashboard = lazy(() =>
  import(
    "../themes/default/routes/SecondaryMarket/Buyer/BuyerDashboard/BuyerDashboard"
  )
);

const RequestedSecurites = lazy(() =>
  import(
    "../themes/default/routes/SecondaryMarket/Seller/SellerTradeDetail/SellerTradeDetail"
  )
);

const PurchasedSharesDetails = lazy(() =>
  import(
    "../themes/default/routes/SecondaryMarket/Buyer/BuyerTradeDetail/BuyerTradeDetail"
  )
);

const CampaignRiskAssessment = lazy(() =>
  import(
    "../themes/default/routes/Contribution/CampaignRiskAssessment/CampaignRiskAssessment"
  )
);
// eslint-disable-next-line
export default [
  {
    path: AppPaths.RESET_PASSWORD_PATH,
    element: <ResetPassword />,
  },
  {
    path: AppPaths.HOME_PATH,
    element: <Home />,
  },
  {
    path: AppPaths.LOGIN_PATH,
    element: <Login />,
  },
  {
    path: AppPaths.SIGNUP_PATH,
    element: <Signup />,
  },
  {
    path: AppPaths.BROWSE_PATH,
    element: <BrowseCompanies />,
  },
  {
    path: AppPaths.BROWSE_SUCCESS_CAMPAIGN_PATH,
    element: <BrowseSuccessCampaigns />,
  },
  {
    path: AppPaths.RAISE_CAPITAL_PATH,
    element: <RaiseCapital />,
  },
  {
    path: AppPaths.FAQ_PATH,
    element: <Faq />,
  },
  {
    path: AppPaths.DASHBOARD_PATH,
    element: <Dashboard />,
  },
  {
    path: AppPaths.FORGOT_PASSWORD_PATH,
    element: <ForgotPassword />,
  },
  {
    path: AppPaths.CAMPAIGN_DETAIL_PAGE,
    element: <CampaignDetails />,
  },
  {
    path: `${AppPaths.PROFILE_PATH}/:slug`,
    element: <Profile />,
  },
  {
    path: AppPaths.PROFILE_SETTINGS_PATH,
    element: <ProfileSettings />,
  },
  {
    path: AppPaths.TWO_FACTOR_SETTINGS_PATH,
    element: <TwoFactorAuthentication />,
  },
  {
    path: AppPaths.EMAIL_PREFERENCES_PATH,
    element: <EmailPreferences />,
  },
  {
    path: AppPaths.CHANGE_PASSWORD_PATH,
    element: <ChangePassword />,
  },
  {
    path: AppPaths.OUTSIDE_LINKS_PATH,
    element: <OutsideLinks />,
  },
  {
    path: AppPaths.INBOX_PATH,
    element: <Inbox />,
  },
  {
    path: AppPaths.USER_NOTIFICATION_PATH,
    element: <UserNotifications />,
  },
  {
    path: AppPaths.INVITE_PATH,
    element: <Invite />,
  },
  {
    path: AppPaths.INVESTMENTS_PATH,
    element: <MyInvestments />,
  },
  {
    path: AppPaths.INVESTMENTACCOUNT_PATH,
    element: <InvestmentAccount />,
  },
  {
    path: AppPaths.PAYMENTOPTIONS_PATH,
    element: <PaymentOptions />,
  },
  {
    path: AppPaths.INVEST_PATH,
    element: <Investment />,
  },
  {
    path: AppPaths.PAYMENT_PATH,
    element: <Payment />,
  },
  {
    path: AppPaths.RECEIPT_PATH,
    element: <InvestmentReceipt />,
  },
  {
    path: AppPaths.COMPANYINFO_PATH,
    element: <CompanyInfo />,
  },
  {
    path: AppPaths.FUNDRAISING_DETAIL_PATH,
    element: <FundraisingDetail />,
  },
  {
    path: AppPaths.STORY_PATH,
    element: <Story />,
  },
  {
    path: AppPaths.FAQS_PATH,
    element: <Faqs />,
  },
  {
    path: AppPaths.EXTRAS_PATH,
    element: <Extras />,
  },
  {
    path: AppPaths.FUNDING_PATH,
    element: <Funding />,
  },
  {
    path: AppPaths.THANK_YOU_PATH,
    element: <ThankYou />,
  },
  {
    path: AppPaths.CAMPAIGN_DASHBOARD_PATH,
    element: <CampaignDashboard />,
  },
  {
    path: AppPaths.REPAYMENTTAB_PATH,
    element: <RepaymentForm />,
  },
  {
    path: AppPaths.REPAYMENT_PATH,
    element: <Repayment />,
  },
  {
    path: AppPaths.SAVED_CAMPAIGN_PATH,
    element: <SavedCampaign />,
  },
  {
    path: AppPaths.REPAYMENT_RECEIPT_PATH,
    element: <RepaymentReceipt />,
  },
  {
    path: AppPaths.HELP_CENTER_PATH,
    element: <HelpCenter />,
  },
  {
    path: AppPaths.QUESTION_LIST_PATH,
    element: <QuestionList />,
  },
  {
    path: AppPaths.SINGLE_QUESTION_PATH,
    element: <SingleQuestion />,
  },
  {
    path: AppPaths.EMAIL_VERIFICATION,
    element: <EmailVerification />,
  },
  {
    path: AppPaths.CONTENT_PAGE,
    element: <ContentPage />,
  },
  {
    path: AppPaths.TERMS_OF_USE,
    element: <Terms />,
  },
  {
    path: AppPaths.ACTIVATE_ACCOUNT,
    element: <AccountActivated />,
  },
  {
    path: AppPaths.PROJECT_GUIDELINES,
    element: <ProjectGuidelines />,
  },
  {
    path: AppPaths.SUCCESS_PAYMENT_RECEIPT_PATH,
    element: <InvestmentPaymentReceipt />,
  },
  {
    path: AppPaths.CONTACT_US_PATH,
    element: <ContactUs />,
  },
  {
    path: AppPaths.ACCOUNT_DELETE_REQUEST_PATH,
    element: <DeleteAccountRequest />,
  },
  {
    path: AppPaths.USER_KYC_PATH,
    element: <UserKYC />,
  },
  {
    path: AppPaths.TWO_FACTOR_AUTHENTICATION_PATH,
    element: <TwoFactorAuth />,
  },
  {
    path: AppPaths.STRIPE_ACCOUNT_CONNECTED_PATH,
    element: <StripeConnect />,
  },
  {
    path: AppPaths.STRIPE_ACH_ACCOUNTS_LIST_PATH,
    element: <StripeACHAccounts />,
  },
  {
    path: AppPaths.PLAID_DWOLLA_ACCOUNT_CONNECT_PATH,
    element: <PlaidDwollaConnect />,
  },
  {
    path: AppPaths.RESEND_VERIFICATION_EMAIL_PATH,
    element: <ResendEmail />,
  },
  {
    path: AppPaths.LOGIN_TWO_FACTOR_AUTHENTICATION_PATH,
    element: <LoginTwoFactorAuth />,
  },
  {
    path: AppPaths.TOP_UP_PAYMENT_PATH,
    element: <TopupPayment />,
  },
  {
    path: AppPaths.TOP_UP_RECEIPT_FAIL_PATH,
    element: <TopupReceiptFail />,
  },
  {
    path: AppPaths.TOP_UP_RECEIPT_PATH,
    element: <TopupReceipt />,
  },
  {
    path: AppPaths.WALLET_PATH,
    element: <Wallet />,
  },
  {
    path: AppPaths.WALLET_PAYMENT_PATH,
    element: <WalletPayment />,
  },
  {
    path: AppPaths.SAVED_INVESTMENT_PATH,
    element: <SaveInvestment />,
  },
  {
    path: AppPaths.MANGOPAY_PATH,
    element: <MangoPayWallet />,
  },
  {
    path: AppPaths.MANGOPAY_TOPUP_RECEIPT_PATH,
    element: <MangoPayTopupReceipt />,
  },
  {
    path: AppPaths.SHARE_CERTIFICATES_PATH,
    element: <ShareCertificates />,
  },
  {
    path: AppPaths.SECONDARY_MARKET_BROWSE_PATH,
    element: <SecondaryMarketBrowse />,
  },
  {
    path: `${AppPaths.SELLER_STEPS_SHARE_DETAILS_PATH}`,
    element: <SellerStepsShareDetails />,
  },
  {
    path: `${AppPaths.SELLER_STEPS_AGREEMENT_PATH}`,
    element: <SellerStepsAgreement />,
  },
  {
    path: `${AppPaths.SELLER_STEPS_SUMMARY_PATH}`,
    element: <SellerStepsSummary />,
  },
  {
    path: `${AppPaths.SECONDARY_MARKET_SELLER_DASHBOARD_PATH}`,
    element: <SellerDashboard />,
  },
  {
    path: `${AppPaths.SECONDARY_MARKET_REQUESTED_SECURITES_PATH}`,
    element: <RequestedSecurites />,
  },
  {
    path: `${AppPaths.BUYER_STEPS_SHARE_DETAILS_PATH}`,
    element: <BuyerStepsShareDetails />,
  },
  {
    path: `${AppPaths.BUYER_STEPS_PAYMENT_PATH}`,
    element: <BuyerStepsPayment />,
  },
  {
    path: `${AppPaths.BUYER_STEPS_SUMMARY_PATH}`,
    element: <BuyerStepsSummary />,
  },
  {
    path: `${AppPaths.SECONDARY_MARKET_BUYER_DASHBOARD_PATH}`,
    element: <BuyerDashboard />,
  },
  {
    path: `${AppPaths.SECONDARY_MARKET_BUYER_DETAIL_PATH}`,
    element: <PurchasedSharesDetails />,
  },
  {
    path: `${AppPaths.CAMPAIGN_RISK_ASSESSMENT_PATH}`,
    element: <CampaignRiskAssessment />,
  },
  {
    path: "*",
    element: <Home />,
  },
];
