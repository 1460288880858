import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import axios from "../../../config/AxiosConfig";
import { TermsConditionContentPageSlug } from "../../../constants";
import { getLanguageCode, getLanguageId } from "../../../helpers";
import {
  CONTENT_PAGE_API,
  COUNTRIES_API,
  INVESTOR_PROFILE,
  GET_CONTRACT_API,
  INVESTOR_PROFILE_UPDATE_API,
} from "../../../store/actions/apiCollections";

const queryParams = {
  params: {
    ...(getLanguageCode() != null && {
      lng: getLanguageCode(),
    }),
  },
};

/* Fetchers */
export const getContentPageFetcher = (url) =>
  axios
    .get(url, {
      params: {
        ...(getLanguageId() != null && {
          language: getLanguageId(),
        }),
      },
    })
    .then((response) => response.data.data.data[0]);

export const getCountriesFetcher = (url) =>
  axios
    .get(url, {
      params: {
        status: true,
      },
    })
    .then((response) => response.data.data.data);

export const getInvestorProfileFetcher = (url) =>
  axios.get(url, queryParams).then((response) => response.data.data.data);

export const getAccreditationContractFetcher = (url) =>
  axios
    .get(url, {
      params: {
        ...(getLanguageId() != null && {
          language: getLanguageId(),
        }),
      },
    })
    .then((response) => response.data.data.data[0]);

export const getSignedAccreditationContractFetcher = (url, { arg }) =>
  axios.post(url, arg, queryParams).then((response) => response.data);

export const getSignedAccreditationContractFetcherPDF = (url, { arg }) =>
  axios.post(url, arg).then((response) => response.data);

export const createUpdateInvestorProfileFetcher = (url, { arg }) => {
  return arg.get("id")
    ? axios
        .patch(url, arg, queryParams)
        .then((response) => response.data.data.data)
    : axios
        .post(url, arg, queryParams)
        .then((response) => response.data.data.data);
};

/* custom hooks */
export function useGetContentPage() {
  const { data: contentPageData } = useSWR(
    `${CONTENT_PAGE_API}${TermsConditionContentPageSlug}/front`,
    getContentPageFetcher,
    {
      keepPreviousData: true,
    }
  );
  return { contentPageData };
}

export function useGetCountries() {
  const { data: countriesData } = useSWR(COUNTRIES_API, getCountriesFetcher, {
    keepPreviousData: true,
  });
  return { countriesData };
}

export function useGetInvestorProfile() {
  const { data: investorProfileData, mutate: investorProfileMutate } = useSWR(
    INVESTOR_PROFILE,
    getInvestorProfileFetcher,
    {
      keepPreviousData: true,
    }
  );
  return { investorProfileData, investorProfileMutate };
}

export function useGetAccreditationContract() {
  const { data: accreditationContractData } = useSWR(
    `${GET_CONTRACT_API}/accreditation-contract`,
    getAccreditationContractFetcher,
    {
      keepPreviousData: true,
    }
  );
  return { accreditationContractData };
}

export function useGetSignedAccreditationContract(url) {
  const {
    data: signedAccreditationContractData,
    trigger: signedAccreditationContractTrigger,
    isMutating: signedAccreditationContractMutating,
  } = useSWRMutation(url, getSignedAccreditationContractFetcher);
  return {
    signedAccreditationContractData,
    signedAccreditationContractTrigger,
    signedAccreditationContractMutating,
  };
}

export function useCreateUpdateInvestorProfile(investAccountId) {
  const {
    data: createUpdateInvestorProfileData,
    trigger: createUpdateInvestorProfileTrigger,
    isMutating: createUpdateInvestorProfileMutating,
    error: createUpdateInvestorProfileError,
  } = useSWRMutation(
    investAccountId
      ? `${INVESTOR_PROFILE_UPDATE_API}${investAccountId}`
      : INVESTOR_PROFILE,
    createUpdateInvestorProfileFetcher
  );

  return {
    createUpdateInvestorProfileData,
    createUpdateInvestorProfileTrigger,
    createUpdateInvestorProfileMutating,
    createUpdateInvestorProfileError,
  };
}
