const AppPaths = {
  HOME_PATH: "/",
  AUTHENTICATED_HOMEPAGE: "/home",
  LOGIN_PATH: "/login",
  SIGNUP_PATH: "/signup",
  LOGOUT_PATH: "/logout",
  FORGOT_PASSWORD_PATH: "/forgot-password",
  BROWSE_PATH: "/browse-companies",
  BROWSE_SUCCESS_CAMPAIGN_PATH: "/browse-succeess-campaign",
  RAISE_CAPITAL_PATH: "/raise-capital",
  FAQ_PATH: "/faq",
  DASHBOARD_PATH: "/dashboard",
  CAMPAIGNDETAILS_PATH: "/atmos-home",
  CHOOSETYPE_PATH: "/create-campaign/choose-type",
  PROFILE_PATH: "/profile",
  PROFILE_SETTINGS_PATH: "/settings/profile",
  TWO_FACTOR_SETTINGS_PATH: "/settings/two-factor-authentication",
  EMAIL_PREFERENCES_PATH: "/settings/email-preferences",
  CHANGE_PASSWORD_PATH: "/settings/change-password",
  OUTSIDE_LINKS_PATH: "/settings/outside-links",
  INVESTMENTACCOUNT_PATH: "/settings/investment-account", // Investment Account Setting
  PAYMENTOPTIONS_PATH: "/settings/payment-options",
  INBOX_PATH: "/inbox",
  NOTIFICATION_PATH: "/user-activities",
  USER_NOTIFICATION_PATH: "/notifications",
  INVITE_PATH: "/invite",
  INVESTMENTS_PATH: "/investments", // My Investment
  INVEST_PATH: "/invest/:id", // Invest Now
  PAYMENT_PATH: "/payment/:id",
  RECEIPT_PATH: "/receipt/:id",
  SUCCESS_PAYMENT_RECEIPT_PATH: "/payment-receipt/:id",
  COMPANYINFO_PATH: "/create-campaign/company-info/:id",
  FUNDRAISING_DETAIL_PATH: "/create-campaign/fundraising-detail/:id",
  STORY_PATH: "/create-campaign/story/:id",
  FAQS_PATH: "/create-campaign/faqs/:id",
  EXTRAS_PATH: "/create-campaign/extras/:id",
  FUNDING_PATH: "/create-campaign/funding/:id",
  THANK_YOU_PATH: "/create-campaign/thank-you/:id",
  CAMPAIGN_DASHBOARD_PATH: "/campaign-dashboard/:id",
  REPAYMENTTAB_PATH: "/campaign-dashboard/repayment/:id",
  REPAYMENT_PATH: "/campaign-dashboard/payment/:id",
  REPAYMENT_RECEIPT_PATH: "/campaign-dashboard/receipt/:id",
  SAVED_CAMPAIGN_PATH: "/saved-campaign",
  RESET_PASSWORD_PATH: "/reset-password",
  CONTENT_PAGE: "/content-page/:slug",
  PROJECT_GUIDELINES: "/project-guidelines",
  CAMPAIGN_DETAIL_PAGE: "/campaign-detail-page/:slug",
  INVESTMENTS_FAIL: "/receipt-fail",
  HELP_CENTER_PATH: "/help-center",
  QUESTION_LIST_PATH: "/help-center/question-list",
  SINGLE_QUESTION_PATH: "/help-center/single-question",
  EMAIL_VERIFICATION: "/users/activate-account/:id",
  TERMS_OF_USE: "/terms",
  ACTIVATE_ACCOUNT: "/activate-account",
  CONTACT_US_PATH: "/contact-us",
  ACCOUNT_DELETE_REQUEST_PATH: "/request-delete-account",
  USER_KYC_PATH: "/user-kyc",
  TWO_FACTOR_AUTHENTICATION_PATH: "/auth-user-verification",
  LOGIN_TWO_FACTOR_AUTHENTICATION_PATH: "/login-user-verification",
  STRIPE_ACCOUNT_CONNECTED_PATH: "/stripe-account-connect",
  STRIPE_ACH_ACCOUNTS_LIST_PATH: "/settings/ach-account-list",
  RESEND_VERIFICATION_EMAIL_PATH: "/resend-email-verification",
  TOP_UP_PAYMENT_PATH: "/topup-payment/:id",
  TOP_UP_RECEIPT_PATH: "/topup-receipt/:id",
  TOP_UP_RECEIPT_FAIL_PATH: "/topup-fail",
  WALLET_PATH: "/settings/wallet",
  WALLET_PAYMENT_PATH: "/wallet-payment/:id",
  SAVED_INVESTMENT_PATH: "/save-investment",
  MANGOPAY_PATH: "/settings/mango-pay",
  MANGOPAY_TOPUP_RECEIPT_PATH: "/mangopay-topup-receipt",
  SHARE_CERTIFICATES_PATH: "/share-certificates",
  /** secondary market routes **/
  SECONDARY_MARKET_BROWSE_PATH: "/secondary-market",
  /* secondary market seller routes*/
  SECONDARY_MARKET_SELLER_DASHBOARD_PATH: "/secondary-market/seller-dashboard",
  SELLER_STEPS_SHARE_DETAILS_PATH:
    "/secondary-market/seller-steps/share-details/:campaignId/:transactionId?",
  SELLER_STEPS_AGREEMENT_PATH:
    "/secondary-market/seller-steps/agreement/:campaignId/:tradeId",
  SELLER_STEPS_SUMMARY_PATH:
    "/secondary-market/seller-steps/summary/:campaignId/:tradeId",
  SECONDARY_MARKET_REQUESTED_SECURITES_PATH:
    "/secondary-market/seller-dashboard/trade-details/:tradeId",

  /* secondary market buyer routes */
  SECONDARY_MARKET_BUYER_DASHBOARD_PATH: "/secondary-market/buyer-dashboard",
  SECONDARY_MARKET_BUYER_DETAIL_PATH:
    "/secondary-market/buyer-dashboard/trade-details/:buyTradeId",
  BUYER_STEPS_SHARE_DETAILS_PATH:
    "/secondary-market/buyer-steps/share-details/:sellTradeId/:buyTradeId?",
  BUYER_STEPS_PAYMENT_PATH:
    "/secondary-market/buyer-steps/payment/:sellTradeId/:buyTradeId",
  BUYER_STEPS_SUMMARY_PATH:
    "/secondary-market/buyer-steps/summary/:sellTradeId/:buyTradeId",
  /* plaid dwolla account connect routes */
  PLAID_DWOLLA_ACCOUNT_CONNECT_PATH: "/settings/ach-account-connect",

  // Manual KYC
  BASIC_INVESTOR_INCOME_DETAILS: "/user-kyc/basic-investor-and-income-details",
  INVESTOR_PREFERENCE_BANK_DETAILS:
    "/user-kyc/investor-preference-and-bank-details",
  PEP_FECTA: "/user-kyc/pep-fecta",
  DECLARATION_TAX_INFORMATION: "/user-kyc/declaration-and-tax-information",
  CAMPAIGN_RISK_ASSESSMENT_PATH: "/campaign-risk-assessment/:campaignId",
};

export default AppPaths;
